import type { Currency, Money } from "./index";
import "./index";

/**
 * @name equals
 * @memberof Money
 */
export const equals =
  <C extends Currency>(a: Money<C>): ((b: Money<C>) => boolean) =>
  (b) => {
    if (a.currency !== b.currency)
      throw new Error(`Currency Mismatch: ${a.currency}, ${b.currency}`);

    if (Number.isNaN(Number(a.amount)) || Number.isNaN(Number(b.amount)))
      throw new Error(`Invalid number passed: ${a.amount}, ${b.amount}`);

    return Number(a.amount) === Number(b.amount);
  };

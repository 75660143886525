import api from "./api";
import type { User } from "./User";

/**
 * https://developers.facebook.com/docs/graph-api/reference/v6.0/profile
 */

/**
 * Request the currently authenticated user's profile; only the name, email, and picture fields.
 */
const me = (): Promise<User> =>
  api<User>({
    path: "/me",
    params: {
      fields: "name,email,picture",
    },
  });

export default me;

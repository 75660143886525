/**
 * https://developers.facebook.com/docs/javascript/reference/FB.api/
 */
type Options = {
  path: string;
  method?: FBapiMethod;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
};

export type FBapiMethod = "get" | "post" | "delete";

function api<Response>({
  path,
  method = "get",
  params = {},
}: Options): Promise<Response> {
  return new Promise((resolve) => {
    window.FB.api(path, method, params, resolve);
  });
}

export default api;

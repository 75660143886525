import React from "react";
import classnames from "classnames";
import Button from "../Button";

import "./style.scss";

// will throw type error if you pass in a className prop
type NoClassName = {
  className?: void;
};

type FormProps = NoClassName & {
  children?: React.ReactNode;
  onSubmit?: (evt: React.SyntheticEvent<HTMLFormElement>) => void;
};

/**
 * @description This is simply a styled form tag
 */
const Form = ({ children, ...props }: FormProps) => (
  <form {...props} className="Form">
    {children}
  </form>
);

type TitleProps = NoClassName & {
  children?: React.ReactNode;
};

/**
 * @description This is simply a styled p used as the title of a form
 */
export const Title = ({ children, ...props }: TitleProps) => (
  <p {...props} className="FormTitle">
    {children}
  </p>
);

type SubmitProps = NoClassName & {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  form?: string;
  loading?: boolean;
  type?: void;
};

/**
 * @description This is simply a styled button used as a submit button for forms
 */
export const Submit = ({
  children,
  className,
  disabled,
  form,
  loading,
}: SubmitProps) => (
  <Button
    className={classnames("FormSubmit", className)}
    disabled={disabled || false}
    form={form}
    loading={loading}
    size="large"
    type="submit"
    variant="primary"
  >
    {children}
  </Button>
);

export { default as Field } from "./Field";
export default Form;

import React from "react";

import { from as visitorPropertiesFrom } from "storefront/Analytics/VisitorProperties";
import { memoizedMeRequest } from "storefront/hooks/useAuthentication";
import useAnalytics from "storefront/hooks/useAnalytics";
import pageView from "storefront/Analytics/EventCreators/pageView";

import Logo from "./components/Logo";
import MobileMenu from "./components/MobileMenu";
import Search from "./components/Search";
import Actions from "./components/Actions";
import MerchandisingMenu from "./components/MerchandisingMenu";

import styles from "./SiteHeader.module.scss";

const SiteHeader = () => {
  const { identify, track } = useAnalytics();

  React.useEffect(() => {
    memoizedMeRequest().then((user) => {
      identify(user, visitorPropertiesFrom(window, user));
    });
    track(pageView());
  }, [identify, track]);

  React.useEffect(() => {
    document.documentElement.style.cssText = "--global-header-height: 70px";
  }, []);
  return (
    <header className={styles.header} id="globalHeader">
      <nav className={styles.nav} aria-label="global">
        <div className={styles.container}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.menu}>
            <MobileMenu />
          </div>
          <div className={styles.actions}>
            <Actions />
          </div>
          <div className={styles.search}>
            <Search />
          </div>
        </div>
      </nav>
      <div className={styles.merchandisingMenu}>
        <MerchandisingMenu />
      </div>
    </header>
  );
};

export default SiteHeader;

import algoliasearch, { SearchClient } from "algoliasearch";

import usePublicConfig from "storefront/hooks/usePublicConfig";

let client: SearchClient | undefined;

function useAlgoliaClient() {
  const { algolia } = usePublicConfig();

  if (!client) {
    client = algoliasearch(algolia.appId, algolia.publicSearchKey);
  }

  return client;
}

export default useAlgoliaClient;

import React, { useState, useEffect, useContext, createContext } from "react";
import type { ComponentType } from "react";
import memoize from "storefront/lib/Function/memoize";
import me from "storefront/GrailedAPI/v1/Users/me";
import type { Authentication } from "storefront/Authentication";
import {
  loading,
  authenticated,
  unauthenticated,
  failed,
} from "storefront/Authentication";
import { isKnownBot } from "storefront/Analytics/isKnownBot";

/**
 * @private
 * @description Because we use a request to this endpoint on every page load and because Known Bots can never be logged in, we're always returning
 * undefined to save making the request and to save GoogleBot crawl budget.
 *
 */
const meIfNotBot = () => (isKnownBot() ? Promise.resolve(undefined) : me());

/**
 * @see {@link Function.memoize} for more information about what "memoizing" is.
 * @description A memoized version of our `/users/me` request.
 *
 */
export const memoizedMeRequest = memoize(meIfNotBot);

const Context = createContext<Authentication>(loading);

type ProviderProps = {
  children: React.ReactNode;
};

export const Provider = ({ children }: ProviderProps) => {
  const [auth, setAuth] = useState<Authentication>(loading);

  useEffect(() => {
    memoizedMeRequest()
      .then((user) => (!user ? unauthenticated : authenticated(user)))
      .catch(failed)
      .then(setAuth);
  }, []);

  return <Context.Provider value={auth}>{children}</Context.Provider>;
};

/**
 * @name useAuthentication
 * @memberof Authentication
 * @description A React Hook that returns an Authentication, based on the `/users/me` API endpoint;
 * hitting that endpoint is our canonical way of determining whether the current visitor is
 * authenticated.
 *
 * This hook uses a memoized request to check the authentication state. That means that this hook
 * is safe to re-use across many components, without fear that we are triggering a ton of API
 * requests. All of the instances of this hook will use the result of the first component to
 * initialize the hook.
 *
 * @param {void}
 * @return {Authentication}
 */
const useAuthentication = (): Authentication => useContext(Context);

export type AuthenticationProps = {
  authentication: Authentication;
};

type PropsWithAuth<OP> = OP & AuthenticationProps;

export function withAuthentication<OwnProps>(
  ComponentToBeWrapped: ComponentType<PropsWithAuth<OwnProps>>,
): ComponentType<OwnProps> {
  const WrappedComponent = (props: OwnProps) => {
    const authentication = useAuthentication();
    return <ComponentToBeWrapped {...props} authentication={authentication} />;
  };

  return WrappedComponent;
}

export default useAuthentication;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import LinkButton from "storefront/components/LinkButton";
import SiteLink from "../SiteLink";
import styles from "./MobileFooter.module.scss";

import siteLinkStyles from "../SiteLink.module.scss";

const MobileFooter = () => {
  const { t } = useTranslation(["common", "helpCenterUrls"]);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={styles.mobileFooter}>
      {t("common::IOS_APP_ID") ? (
        <SiteLink
          className={styles.siteLink}
          href={`https://itunes.apple.com/app/${t("common::IOS_APP_ID")}`}
          rel="nofollow"
          isMobile
        >
          iOS App
        </SiteLink>
      ) : null}
      <SiteLink
        className={styles.siteLink}
        href={t("helpCenterUrls::helpCenterHome")}
        isMobile
      >
        Help &amp; FAQ
      </SiteLink>
      <SiteLink className={styles.siteLink} href="/about/contact" isMobile>
        Contact
      </SiteLink>
      <div className={styles.break} />
      {mounted ? (
        <LinkButton
          // noop -- the modal opens magically
          onClick={() => {}}
          variant="inherit"
          className={cn(
            "ot-sdk-show-settings", // this className handles the modal opening & closing
            styles.siteLink,
            siteLinkStyles.siteLink,
            siteLinkStyles.isMobile,
          )}
        >
          Your Privacy Choices
        </LinkButton>
      ) : null}
    </div>
  );
};

export default MobileFooter;

import React from "react";

import usePublicConfig from "storefront/hooks/usePublicConfig";
import findAlgoliaIndexName from "storefront/Config/PublicConfig/findAlgoliaIndexName";

import useAlgoliaClient from "./useAlgoliaClient";

type SuggestionHit = {
  query: string;
};

type DesignerHit = {
  name: string;
  id: number;
  slug: string;
};

type Results = {
  querySuggestions: SuggestionHit[];
  designers: DesignerHit[];
};

type HeaderSearch = {
  query: string;
  setQuery: (query: string) => void;
  results: Results;
};

type QueryCache = {
  [query: string]: {
    querySuggestions: SuggestionHit[];
    designers: DesignerHit[];
  };
};

const queryCache: QueryCache = {};

const useHeaderSearch = (): HeaderSearch => {
  const [query, setQuery] = React.useState<string>("");
  const [designers, setDesigners] = React.useState<DesignerHit[]>([]);
  const [suggestions, setSuggestions] = React.useState<SuggestionHit[]>([]);
  const publicConfig = usePublicConfig();
  const client = useAlgoliaClient();

  React.useEffect(() => {
    if (window.SEARCH?.query) {
      setQuery(window.SEARCH.query);
    }
  }, []);

  React.useEffect(() => {
    const querySuggestionIndex =
      findAlgoliaIndexName("queries", "default")(publicConfig) || "";

    const designersIndex =
      findAlgoliaIndexName("designers", "default")(publicConfig) || "";

    const fetchSuggestions = async () => {
      if (queryCache[query]) {
        setSuggestions(queryCache[query].querySuggestions);
        setDesigners(queryCache[query].designers);
        return;
      }
      try {
        const { results } = await client.search<DesignerHit | SuggestionHit>([
          {
            indexName: querySuggestionIndex,
            query,
            params: {
              hitsPerPage: 8,
            },
          },
          {
            indexName: designersIndex,
            query,
            params: {
              hitsPerPage: 5,
            },
          },
        ]);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setSuggestions(results[0].hits as SuggestionHit[]);

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        setDesigners(results[1].hits as DesignerHit[]);

        queryCache[query] = {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          querySuggestions: results[0].hits as SuggestionHit[],
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          designers: results[1].hits as DesignerHit[],
        };
      } catch (err) {
        console.log(err);
      }
    };

    const debounceTimeout = setTimeout(fetchSuggestions, 300);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [query, client, publicConfig]);

  return {
    query,
    setQuery,
    results: {
      querySuggestions: suggestions,
      designers,
    },
  };
};

export default useHeaderSearch;

import React from "react";
import { useTranslation } from "react-i18next";
import Link from "storefront/components/Link";
import { Error } from "../Layout";

export const USER_NOT_FOUND = "USER_NOT_FOUND" as const;
export const BANNED = "BANNED" as const;

type Props = {
  error: string | null | undefined;
  onSignupClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
};

const Errors = ({ error, onSignupClick }: Props) => {
  const { t } = useTranslation("helpCenterUrls");
  const handleSignup = (evt: React.SyntheticEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (onSignupClick) {
      onSignupClick(evt);
    }
  };

  if (error === USER_NOT_FOUND) {
    return (
      <Error>
        Looks like this email isn&apos;t registered to an account.{" "}
        <Link href="/users/sign_up" onClick={handleSignup} variant="underline">
          Create account instead
        </Link>
      </Error>
    );
  }

  if (error === BANNED) {
    return (
      <Error>
        Your account has been frozen for violating our{" "}
        <Link href={t("codeOfConduct")} target="_blank" variant="underline">
          code of conduct.
        </Link>{" "}
        For additional assistance,{" "}
        <Link href="/about/contact" target="_blank" variant="underline-arrow">
          contact us
        </Link>
      </Error>
    );
  }

  return <Error>{error}</Error>;
};

export default Errors;

export const ALL_PARTIES_FULLY_REFUNDED: "all_parties_fully_refunded" =
  "all_parties_fully_refunded";
export const ALL_PARTIES_PARTIALLY_REFUNDED: "all_parties_partially_refunded" =
  "all_parties_partially_refunded";
export const BUYER_FULLY_REFUNDED: "buyer_fully_refunded" =
  "buyer_fully_refunded";
export const AUTHORIZED: "authorized" = "authorized";
export const CAPTURED: "captured" = "captured";
export const CHARGE_IN_PROGRESS: "charge_in_progress" = "charge_in_progress";
export const DISBURSED: "disbursed" = "disbursed";
export const HELD: "held" = "held";
export const PENDING: "pending" = "pending";
export const REVERSED: "reversed" = "reversed";
export const VOIDED: "voided" = "voided";
export type TransactionPaymentStatus =
  | typeof ALL_PARTIES_FULLY_REFUNDED
  | typeof ALL_PARTIES_PARTIALLY_REFUNDED
  | typeof BUYER_FULLY_REFUNDED
  | typeof AUTHORIZED
  | typeof CAPTURED
  | typeof CHARGE_IN_PROGRESS
  | typeof DISBURSED
  | typeof HELD
  | typeof PENDING
  | typeof REVERSED
  | typeof VOIDED;

export const SHIPMENT_EXCEPTION = "Exception" as const;
export const SHIPMENT_EXPIRED = "Expired" as const;
export const SHIPMENT_DELIVERED = "Delivered" as const;
export const SHIPMENT_ATTEMPT_FAIL = "AttemptFail" as const;
export const SHIPMENT_OUT_FOR_DELIVERY = "OutForDelivery" as const;
export const SHIPMENT_IN_TRANSIT = "InTransit" as const;
export const SHIPMENT_INFO_RECEIVED = "InfoReceived" as const;
export const SHIPMENT_PENDING = "Pending" as const;
export const SHIPMENT_NO_TRACKING = null;

export type TransactionShipmentStatus =
  | typeof SHIPMENT_PENDING
  | typeof SHIPMENT_EXCEPTION
  | typeof SHIPMENT_EXPIRED
  | typeof SHIPMENT_DELIVERED
  | typeof SHIPMENT_ATTEMPT_FAIL
  | typeof SHIPMENT_OUT_FOR_DELIVERY
  | typeof SHIPMENT_IN_TRANSIT
  | typeof SHIPMENT_INFO_RECEIVED
  | typeof SHIPMENT_NO_TRACKING;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import sellFormEN from "../locales/en/sellForm.json";
import listingPageEN from "../locales/en/listingPage.json";
import helpCenterUrlsEN from "../locales/en/helpCenterUrls.json";

// eslint-disable-next-line import/no-dynamic-require, @typescript-eslint/no-var-requires
const commonEN = require(`../locales/${
  process.env.MARKETPLACE || ""
}/en/common.json`);

const resources = {
  en: {
    common: commonEN,
    sellForm: sellFormEN,
    listingPage: listingPageEN,
    helpCenterUrls: helpCenterUrlsEN,
  },
};
export const initPromise = i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  defaultNS: "common",
  fallbackNS: "common",
  returnNull: true,
  nsSeparator: "::",
});

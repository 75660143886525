import * as React from "react";
import {
  Root as NavigationMenu,
  List as NavigationMenuList,
  Item as NavigationMenuItem,
  Trigger as NavigationMenuTrigger,
  Content as BaseNavigationMenuContent,
  Viewport as NavigationMenuViewport,
  Link as NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";
import cn from "classnames";

import { Typography } from "storefront/ui/typography";
import { MENSWEAR, WOMENSWEAR } from "storefront/Department";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import useAnalytics from "storefront/hooks/useAnalytics";
import CaretIcon from "./components/CaretIcon";

import DesignersDropdown from "./components/DesignersDropdown";
import CategoryDropdown from "./components/CategoryDropdown";

import styles from "./MerchandisingMenu.module.scss";

const NAV_ITEM_IDS = {
  designers: "designers",
  cybersale: "cybersale",
  holidaygifts: "holidaygifts",
  staffpicks: "staffpicks",
  sneakers: "sneakers",
  collections: "collections",
  editorial: "editorial",
  menswear: "menswear",
  womenswear: "womenswear",
};

type BaseNavigationMenuContentProps = React.ComponentProps<
  typeof BaseNavigationMenuContent
> & {
  active: boolean;
};

const ClickOnlyNavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuTrigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuTrigger>
>((props, ref) => (
  <NavigationMenuTrigger
    ref={ref}
    {...props}
    onPointerEnter={(event) => event.preventDefault()}
    onPointerMove={(event) => event.preventDefault()}
    onPointerLeave={(event) => event.preventDefault()}
  />
));
ClickOnlyNavigationMenuTrigger.displayName = NavigationMenuTrigger.displayName;

const NavigationMenuContent = ({
  key,
  forceMount: _,
  className,
  active,
  ...rest
}: BaseNavigationMenuContentProps) => {
  return (
    <BaseNavigationMenuContent
      key={String(key)}
      forceMount
      {...rest}
      className={cn(className, {
        [styles.hidden]: !active,
      })}
    />
  );
};

const MerchandisingMenu = () => {
  const [value, setValue] = React.useState<string | undefined>(undefined);
  const { track } = useAnalytics();

  const setNavigationMenuValue = (v: string | undefined) => {
    setValue(v);
  };
  return (
    <NavigationMenu
      value={value}
      onValueChange={setNavigationMenuValue}
      className={styles.root}
    >
      <NavigationMenuList className={styles.list}>
        <NavigationMenuItem value={NAV_ITEM_IDS.designers}>
          <ClickOnlyNavigationMenuTrigger
            className={cn(styles.item, styles.trigger)}
          >
            <Typography variant="title-sm">DESIGNERS</Typography>{" "}
            <CaretIcon className={styles.caret} aria-hidden="true" />
          </ClickOnlyNavigationMenuTrigger>
          <NavigationMenuContent
            active={value === NAV_ITEM_IDS.designers}
            className={styles.content}
          >
            <DesignersDropdown />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem value={NAV_ITEM_IDS.menswear}>
          <ClickOnlyNavigationMenuTrigger
            className={cn(styles.item, styles.trigger)}
          >
            <Typography variant="title-sm">MENSWEAR</Typography>{" "}
            <CaretIcon className={styles.caret} aria-hidden="true" />
          </ClickOnlyNavigationMenuTrigger>
          <NavigationMenuContent
            active={value === NAV_ITEM_IDS.menswear}
            className={styles.content}
          >
            <CategoryDropdown department={MENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem value={NAV_ITEM_IDS.womenswear}>
          <ClickOnlyNavigationMenuTrigger
            className={cn(styles.item, styles.trigger)}
          >
            <Typography variant="title-sm">WOMENSWEAR</Typography>{" "}
            <CaretIcon className={styles.caret} aria-hidden="true" />
          </ClickOnlyNavigationMenuTrigger>
          <NavigationMenuContent
            active={value === NAV_ITEM_IDS.womenswear}
            className={styles.content}
          >
            <CategoryDropdown department={WOMENSWEAR} />
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            onClick={() => {
              track(navigationClicked("sneakers", "global_header"));
            }}
            className={styles.item}
            href="/categories/sneakers"
          >
            <Typography variant="title-sm">SNEAKERS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            onClick={() => {
              track(navigationClicked("staff_picks", "global_header"));
            }}
            className={styles.item}
            href="/shop/staff-picks"
          >
            <Typography variant="title-sm">STAFF PICKS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            onClick={() => {
              track(navigationClicked("collections", "global_header"));
            }}
            className={styles.item}
            href="/collections"
          >
            <Typography variant="title-sm">COLLECTIONS</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuLink
            onClick={() => {
              track(navigationClicked("editorial", "global_header"));
            }}
            className={styles.item}
            href="/drycleanonly"
          >
            <Typography variant="title-sm">EDITORIAL</Typography>
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
      <div className={styles.viewportContainer}>
        <NavigationMenuViewport
          onPointerEnter={(event) => event.preventDefault()}
          onPointerMove={(event) => event.preventDefault()}
          onPointerLeave={(event) => event.preventDefault()}
          className={styles.viewport}
        />
      </div>
    </NavigationMenu>
  );
};

export default MerchandisingMenu;

import type { Authorization } from "./Authorization";
import type { User } from "./User";

/**
 * https://developer.apple.com/documentation/sign_in_with_apple/signinresponsei
 */
export type Success = {
  authorization: Authorization;
  user?: User;
};

/**
 * https://developer.apple.com/documentation/sign_in_with_apple/signinerrori
 */
export type Failure = {
  error: "user_cancelled_authorize";
};

/**
 * https://developer.apple.com/documentation/sign_in_with_apple/authi/3261300-signin
 */
const signIn = (): Promise<Success> => {
  if (!window.AppleID) throw new Error("AppleID is missing in window.");
  return window.AppleID.auth.signIn();
};

export default signIn;

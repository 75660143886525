import React from "react";

import classNames from "classnames";
import styles from "./Link.module.scss";

export type LinkVariant =
  | "inherit"
  | "default"
  | "underline"
  | "super-link"
  | "underline-arrow"
  | "circle-plus";

type Props = {
  children: React.ReactNode;
  className?: string;
  href: string;
  target?: string;
  rel?: string;
  /** Determines the styles of the link, see the stories for more detail. */
  variant?: LinkVariant;
} & React.HTMLAttributes<HTMLAnchorElement>;

export const getLinkStyles = (variant: LinkVariant) =>
  classNames(styles.link, {
    [styles.inherit]: variant === "inherit",
    [styles.underline]: variant === "underline",
    [styles.underlineArrow]: variant === "underline-arrow",
    [styles.circlePlus]: variant === "circle-plus",
    [styles.superLink]: variant === "super-link",
    [styles.default]: variant === "default",
  });

/**
 * Simple wrapper around anchor tags.
 * Has different variants to help with styling in different situations.
 * On its own it does not have any text styles, so it is best used in combination
 * with a `Text` component.
 */
const Link = ({
  children,
  href,
  className,
  rel,
  variant = "default",
  ...props
}: Props) => (
  <a
    {...props}
    href={href}
    rel={rel}
    className={classNames(getLinkStyles(variant), className)}
  >
    {children}
  </a>
);

export default Link;

import React from "react";
import classNames from "classnames";
import { TEXT_CLASS_NAME } from "../Text";

import styles from "./SmallTitle.module.scss";

type Props = {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const SmallTitle = ({ as: As = "p", className, ...props }: Props) => (
  <As
    {...props}
    className={classNames(TEXT_CLASS_NAME, styles.smallTitle, className)}
  />
);

export default SmallTitle;

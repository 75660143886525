import React from "react";
import useImagesSource from "../hooks/useImagesSource";
import type { ContentfulImage } from "../Contentful/Image";

type Props = {
  images: Array<ContentfulImage>;
  alt: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const Picture = ({ images, alt, ...rest }: Props) => {
  const [src, srcSet] = useImagesSource(images);
  return <img {...rest} src={src} srcSet={srcSet} alt={alt} />;
};

export default Picture;

import React from "react";

import useAnalytics from "storefront/hooks/useAnalytics";
import {
  Content,
  ContentContainer,
  ContentHeader,
  ContentLink,
  ContentColumn,
  ContentCTA,
} from "../Content";

const POPULAR_DESIGNERS: {
  slug: string;
  name: string;
}[] = [
  {
    name: "Acne Studios",
    slug: "acne-studios",
  },
  {
    name: "Amiri",
    slug: "amiri",
  },
  {
    name: "Arc'teryx",
    slug: "arcteryx",
  },
  {
    name: "Balenciaga",
    slug: "balenciaga",
  },
  {
    name: "Bape",
    slug: "bape",
  },
  {
    name: "Bottega Veneta",
    slug: "bottega-veneta",
  },
  {
    name: "Carhartt",
    slug: "carhartt",
  },
  {
    name: "Celine",
    slug: "celine",
  },
  {
    name: "Chanel",
    slug: "chanel",
  },
  {
    name: "Chrome Hearts",
    slug: "chrome-hearts",
  },
  {
    name: "Comme des Garçons",
    slug: "comme-des-garcons",
  },
  {
    name: "Dior",
    slug: "dior",
  },
  {
    name: "Enfants Riches Déprimés",
    slug: "enfants-riches-deprimes",
  },
  {
    name: "Gucci",
    slug: "gucci",
  },
  {
    name: "Kapital",
    slug: "kapital",
  },
  {
    name: "Louis Vuitton",
    slug: "louis-vuitton",
  },
  {
    name: "Maison Margiela",
    slug: "maison-margiela",
  },
  {
    name: "Moncler",
    slug: "moncler",
  },
  {
    name: "Nike",
    slug: "nike",
  },
  {
    name: "Polo Ralph Lauren",
    slug: "polo-ralph-lauren",
  },
  {
    name: "Prada",
    slug: "prada",
  },
  {
    name: "Raf Simons",
    slug: "raf-simons",
  },
  {
    name: "Rick Owens",
    slug: "rick-owens",
  },
  {
    name: "Saint Laurent Paris",
    slug: "saint-laurent-paris",
  },
  {
    name: "Stone Island",
    slug: "stone-island",
  },
  {
    name: "Stussy",
    slug: "stussy",
  },
  {
    name: "Supreme",
    slug: "supreme",
  },
  {
    name: "Undercover",
    slug: "undercover",
  },
  {
    name: "Vetements",
    slug: "vetements",
  },
  {
    name: "Vintage",
    slug: "vintage",
  },
  {
    name: "Vivienne Westwood",
    slug: "vivienne-westwood",
  },
  {
    name: "Yohji Yamamoto",
    slug: "yohji-yamamoto",
  },
];

const ITEMS_PER_COLUMN = 8;

const DesignersDropdown = () => {
  const columns = Math.ceil(POPULAR_DESIGNERS.length / ITEMS_PER_COLUMN);

  const { track } = useAnalytics();

  return (
    <Content columns={columns}>
      <ContentHeader>Shop Popular Designers</ContentHeader>
      <ContentContainer>
        {[...Array(columns)].map((_, index) => (
          <ContentColumn key={index}>
            {POPULAR_DESIGNERS.slice(
              index * ITEMS_PER_COLUMN,
              index * ITEMS_PER_COLUMN + ITEMS_PER_COLUMN,
            ).map((designer) => (
              <ContentLink
                key={designer.slug}
                href={`/designers/${designer.slug}`}
                from="sub_header_designer_dropdown"
              >
                {designer.name}
              </ContentLink>
            ))}
          </ContentColumn>
        ))}
      </ContentContainer>
      <ContentCTA href="/designers" from="sub_header_designer_dropdown">
        See All Designers
      </ContentCTA>
    </Content>
  );
};

export default DesignersDropdown;

import type { Id } from "storefront/lib/Id";

type Group = {
  createdAt: string;
  id: Id;
  name: string;
  parentId: Id | null;
  updatedAt: string;
};
export type ChildrenGroup = {
  group: Group;
  children?: Array<ChildrenGroup>;
};
export type Groups = {
  group: Group;
  children: Array<ChildrenGroup>;
};

export const emptyGroup: Group = {
  createdAt: "",
  id: "",
  name: "",
  parentId: null,
  updatedAt: "",
};

export const emptyGroups: Groups = {
  group: emptyGroup,
  children: [],
};

import React from "react";

import classnames from "classnames";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Button.module.scss";

export type Type = "button" | "submit" | "reset";
export type Size = "small" | "medium" | "large";
export type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "important"
  | "important-secondary"
  | "destructive"
  | "tertiary-destructive"
  | "alert"
  | "unstyled";

type BaseProps = {
  children: React.ReactNode;

  /** Extend this component's styles with additional CSS classes */
  className?: string;

  /** Will apply appropriate styles as well as disable clicking */
  disabled?: boolean;

  /** Specifies which form this button belongs to * */
  form?: string;

  /** Will display a loading animation. This disables the onClick prop */
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown;

  /** Button html attribute type */
  type?: Type;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type StyledProps = {
  /** The size controls font size as well as padding and min-width */
  size: Size;

  /** Controls the color theme of the button */
  variant: Variant;
};

type UnstyledProps = Omit<BaseProps, "size"> & {
  variant: Extract<Variant, "unstyled">;
  size?: undefined;
};

type Props = BaseProps & (StyledProps | UnstyledProps);

// The loading param is optional because this function is also used
// by the ButtonLink component (which does not support loading).
export const getClassName = (
  size: Size | null | undefined,
  variant: Variant,
  className: string | null | undefined,
  disabled: boolean,
  loading?: boolean,
): string =>
  classnames(
    styles.button,
    size ? styles[size] : null,
    variant !== "unstyled" ? styles[variant] : null,
    className,
    {
      [styles.loading]: loading,
      [styles.disabled]: disabled,
    },
  );

/**
 * This component should be used anytime you need an interactive element.
 *
 * This should **NOT** be used if you are linking to a new url.
 * Please see the `ButtonLink` component instead.
 * If you need interactivity and NOT link behavior but you need it to *look* like a link try the `tertiary` variants.
 *
 * The loading animation will not show if the disabled prop is true.
 */
const Button = ({
  children,
  className,
  disabled = false,
  form,
  loading,
  onClick,
  size,
  type = "button",
  variant,
  ...props
}: Props) => (
  <button
    {...props}
    className={getClassName(
      size,
      variant,
      className,
      disabled,
      !disabled && loading,
    )}
    disabled={disabled}
    form={form}
    onClick={!loading ? onClick : undefined}
    /* eslint-disable-next-line react/button-has-type */
    type={type}
  >
    {children}
  </button>
);

export default Button;

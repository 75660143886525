import React, { ComponentType } from "react";
import useSWR from "swr";

import { PublicConfig } from "storefront/Config/PublicConfig";
import config from "storefront/GrailedAPI/v1/Config/getConfig";
import { camelize } from "camelscore";

export const key = "public_config";

let preloadedDataFromWindow: PublicConfig | undefined;

if (typeof window !== "undefined" && window.PUBLIC_CONFIG) {
  preloadedDataFromWindow = camelize(window.PUBLIC_CONFIG);
}

const options = {
  suspense: true,
  revalidateIfStale: false,
  revalidateOnReconnect: false,
  revalidateOnFocus: false,
  fallbackData: preloadedDataFromWindow,
};

const usePublicConfig = (): PublicConfig => {
  const { data } = useSWR<PublicConfig, Error>(key, config, options);
  if (!data) throw new Error("Public Config not found");
  return data;
};

export type PublicConfigProps = {
  config: PublicConfig | null | undefined;
};

type PropsWithConfig<OP> = OP & PublicConfigProps;

export function withPublicConfig<OwnProps>(
  ComponentToBeWrapped: ComponentType<PropsWithConfig<OwnProps>>,
): ComponentType<OwnProps> {
  const WrappedComponent = (props: OwnProps) => {
    const publicConfig = usePublicConfig();
    return <ComponentToBeWrapped {...props} config={publicConfig} />;
  };

  return WrappedComponent;
}
export default usePublicConfig;

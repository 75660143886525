import * as React from "react";
import { IconProps } from "storefront/ui/icons/types";

export const MessagesOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        ref={forwardedRef}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 17.5H5.5H19L20.5 16V7L19 5.5H5L3.5 7V17.5ZM2 23V6L4 4H20L22 6V17L20 19H5.5L2 23Z"
          fill={color}
        />
      </svg>
    );
  },
);

export default MessagesOutlineIcon;

import type { AuthResponse } from "./AuthResponse";

/**
 * Based on https://developers.facebook.com/docs/facebook-login/web/
 */
export type Success = {
  status: "connected";
  authResponse: AuthResponse;
};
export type Failure = {
  status: "not_authorized" | "unknown";
};
export type Response = Success | Failure;

export type FBloginOptions = {
  scope?: string;
  return_scopes?: boolean;
};

const options = {
  scope: "public_profile,user_friends,email",
  return_scopes: false,
};

const login = () =>
  new Promise<AuthResponse>((resolve, reject) => {
    const onLogin = (res: Response) => {
      if (res.status === "connected") {
        resolve(res.authResponse);
      } else {
        reject(res);
      }
    };

    window.FB.login(onLogin, options);
  });

export default login;

import React, { SVGProps } from "react";

const CaretIcon = React.forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        {...props}
        ref={ref}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.45952 9.16736L11.6464 5.98047L12.3535 6.68758L8.87374 10.1674L7.12619 10.1674L3.64641 6.68758L4.35352 5.98047L7.5404 9.16736L8.45952 9.16736Z"
          fill="black"
        />
      </svg>
    );
  },
);

export default CaretIcon;

import React from "react";
import classNames from "classnames";
import { TEXT_CLASS_NAME } from "../Text";
import styles from "./Body.module.scss";

type Props = {
  /**
   * Controls the underlying HTML tag that is rendered.
   * ex: `h1`, `span`, `p` etc.
   */
  as?: keyof JSX.IntrinsicElements;
  href?: string;
  target?: string;
  rel?: string;
  /** *Additional* classnames */
  className?: string;
} & React.HTMLAttributes<HTMLOrSVGElement>;

/**
 * This should be used anywhere you need the Body text style.
 *
 * For semantics and SEO purposes, you can control the underlying HTML that gets rendered using the `as` prop.
 * */
const Body = ({ as: As = "p", className, ...props }: Props) => (
  <As
    {...props}
    className={classNames(styles.body, TEXT_CLASS_NAME, className)}
  />
);

export default Body;

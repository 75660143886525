import type { Reducer } from "redux";
import FlashManager from "storefront/lib/flash/FlashManager";
import type { Action } from "storefront/Action";
import type { Articles } from "../types/State/Articles";
import {
  FETCH_ARTICLES_REQUEST,
  FETCH_ARTICLES_SUCCESS,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAILURE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  NEW_ARTICLE,
  EDIT_ARTICLE,
  REMOVE_ARTICLE,
  VIEW_ARTICLES_LIST,
  CHANGE_ARTICLE_TAGS,
  SET_ARTICLE_HOMEPAGE_PREVIEW_SUCCESS,
  SET_ARTICLE_HOMEPAGE_PREVIEW_FAILURE,
} from "../constants/action_types";

const initialState: Articles = {
  isLoadingArticles: false,
  isViewingList: false,
  isEditing: false,
  isPreviewing: false,
  currentArticle: null,
  articles: {
    data: [],
    metadata: null,
  },
  franchises: [],
  homepagePreviewArticleIds: [],
  error: null,
  hasMoreArticles: false,
};

const articles: Reducer<Articles, Action> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_REQUEST:
      return { ...state, isLoadingArticles: true };

    case FETCH_ARTICLES_SUCCESS: {
      const existingArticles = action.payload.isNewSearch
        ? []
        : state.articles.data;
      const data = [
        ...existingArticles,
        ...action.payload.articles.data,
      ].reduce(
        (acc, a) =>
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
          acc.map((i) => i.id).indexOf(a.id) !== -1 ? acc : [...acc, a],
        [],
      );
      const { metadata } = action.payload.articles;
      return {
        ...state,
        isLoadingArticles: false,
        hasMoreArticles: action.payload.articles.data.length > 0,
        error: null,
        articles: {
          data,
          metadata,
        },
      };
    }

    case NEW_ARTICLE:
      return {
        ...state,
        isViewingList: false,
        isEditing: true,
        error: null,
        currentArticle: action.payload.article,
      };

    case EDIT_ARTICLE:
      return {
        ...state,
        isViewingList: false,
        isEditing: true,
        error: null,
        currentArticle: action.payload.article,
      };

    case REMOVE_ARTICLE:
      return {
        ...state,
        articles: {
          ...state.articles,
          data: state.articles.data.filter(
            (a) => a.id !== action.payload.article.id,
          ),
        },
      };

    case CREATE_ARTICLE_SUCCESS:
      return { ...state, error: null, currentArticle: action.payload.article };

    case CREATE_ARTICLE_FAILURE:
      if (
        action.payload.error.body &&
        action.payload.error.body.error &&
        action.payload.error.body.error.message
      ) {
        FlashManager.getInstance().alert(
          action.payload.error.body.error.message,
        );
      }

      return { ...state, error: action.payload.error };

    case UPDATE_ARTICLE_SUCCESS:
      return { ...state, error: null, currentArticle: action.payload.article };

    case UPDATE_ARTICLE_FAILURE:
      FlashManager.getInstance().alert(action.payload.error.body.error.message);
      return { ...state, error: action.payload.error };

    case VIEW_ARTICLES_LIST:
      return { ...state, error: null, isViewingList: true, isEditing: false };

    case CHANGE_ARTICLE_TAGS: {
      if (!state.currentArticle) return state;
      return {
        ...state,
        currentArticle: {
          ...state.currentArticle,
          tagList: action.payload.tags,
        },
      };
    }

    case SET_ARTICLE_HOMEPAGE_PREVIEW_SUCCESS:
      FlashManager.getInstance().notice("Saved!");
      return { ...state, homepagePreviewArticleIds: action.payload.data };

    case SET_ARTICLE_HOMEPAGE_PREVIEW_FAILURE:
      FlashManager.getInstance().alert(
        "There was a problem saving this article id - check to see that it exists and is publishe0d.",
      );
      return state;

    default:
      return state;
  }
};

export default articles;

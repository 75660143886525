import React from "react";
import classnames from "classnames";

import { Typography } from "storefront/ui/typography";
import useAnalytics from "storefront/hooks/useAnalytics";
import navigationClicked from "storefront/Analytics/EventCreators/navigationClicked";
import { Department } from "storefront/Department";
import styles from "./Content.module.scss";

export const ContentHeader = ({ children }: { children: React.ReactNode }) => (
  <Typography className={styles.header} variant="headline">
    {children}
  </Typography>
);

export const ContentLink = ({
  children,
  href,
  from,
  department,
}: {
  children: string;
  href?: string;
  from: string;
  department?: Department;
}) => {
  const { track } = useAnalytics();

  const handleClick = (name: string) => {
    track(navigationClicked(name, from, department));
  };

  return (
    <a
      className={styles.link}
      href={href}
      onClick={() => handleClick(children)}
    >
      <Typography variant="callout">{children}</Typography>
    </a>
  );
};

export const ContentContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.container}>{children}</div>;
};

export const ContentColumn = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.column}>{children}</div>;
};

export const ContentColumnHeader = ({
  children,
  href,
  from,
  department,
}: {
  children: string;
  href: string;
  from: string;
  department?: Department;
}) => {
  const { track } = useAnalytics();

  const trackClick = (name: string) => {
    track(navigationClicked(name, from, department));
  };
  return (
    <a
      className={classnames(styles.link)}
      onClick={() => trackClick(children)}
      href={href}
    >
      <Typography variant="sub-head" className={styles.capitalize}>
        {children}
      </Typography>
    </a>
  );
};

export const Content = ({
  children,
  columns = 6,
}: {
  children: React.ReactNode;
  columns?: number;
}) => {
  return (
    <div
      // Ignoring this rule here instead of changing the React.CSSProperties type globally
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      style={{ "--content-grid-columns": columns } as React.CSSProperties}
      className={styles.content}
    >
      {children}
    </div>
  );
};

export const ContentCTA = ({
  children,
  href,
  from,
  department,
}: {
  children: string;
  href: string;
  from: string;
  department?: Department;
}) => {
  const { track } = useAnalytics();
  const trackClick = () => {
    track(navigationClicked(children, from, department));
  };
  return (
    <a
      onClick={trackClick}
      className={classnames(styles.link, styles.cta)}
      href={href}
    >
      <Typography variant="button-sm">{children}</Typography>
    </a>
  );
};

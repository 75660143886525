import type { Id } from "../../../lib/Id";
import type { User } from "../../../User";
import { put } from "../../request";

/**
 * https://www.grailed.com/internal/api-docs#operation/updateUserById
 */
type Params = {
  username?: string;
  email?: string;
  location?: string;
  height?: string;
  weight?: string;
  name?: string;
  avatarUrl?: string | null;
};
type Response = {
  data: User;
};

const unpack = (res: Response): User => res.data;

const update = (id: Id, params: Params): Promise<User> =>
  put<Response>(`/api/users/${id}`, params).then(unpack);

export default update;

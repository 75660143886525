import type { Money, Currency } from "./index";
import "./index";

const percentDifference = <C extends Currency>(
  current: Money<C>,
  original: Money<C>,
): number => {
  if (current.currency !== original.currency)
    throw new Error(
      `Currency Mismatch: ${current.currency}, ${original.currency}`,
    );
  const originalAmount = Number(original.amount);
  const currentAmount = Number(current.amount);
  const rawDiff = originalAmount - currentAmount;
  const percentOff = rawDiff / originalAmount;
  const percentOffNumber = Math.floor(percentOff * 100);
  return percentOffNumber;
};

export default percentDifference;

import React from "react";

import classNames from "classnames";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Badge.module.scss";

export type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "alert"
  | "inactive"
  | "success"
  | "notify"
  | "warning";
type Props = {
  children: React.ReactNode;
  className?: string;
  variant?: Variant;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const Badge = ({ children, variant, className, ...props }: Props) => {
  return (
    <span
      {...props}
      className={classNames(
        styles.badge,
        variant && variant in styles ? styles[variant] : "",
        className,
      )}
    >
      {children}
    </span>
  );
};

export default Badge;

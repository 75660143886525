import React, { ComponentProps } from "react";
import Linkify from "linkify-react";

const options: ComponentProps<typeof Linkify>["options"] = {
  // Disable "mailto" links when rendering links
  validate: {
    email: false,
  },
};

export default function LinkifyComponent(
  props: ComponentProps<typeof Linkify>,
) {
  return <Linkify options={{ ...options, ...props.options }} {...props} />;
}

import type { PublicConfig } from "storefront/Config/PublicConfig";

/**
 * https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js
 */
const init = ({
  appleSignInClientId,
  appleSignInRedirectUri,
}: PublicConfig) => {
  if (!window.AppleID) throw new Error("AppleID is missing in window.");

  window.AppleID.auth.init({
    clientId: appleSignInClientId,
    redirectURI: appleSignInRedirectUri,
    scope: "name email",
    usePopup: true,
  });
};

export default init;

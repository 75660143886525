import React, { Fragment } from "react";

import useCategories from "storefront/hooks/useCategories";
import { Department } from "storefront/Department";

import {
  Content,
  ContentContainer,
  ContentHeader,
  ContentLink,
  ContentColumn,
  ContentCTA,
  ContentColumnHeader,
} from "../Content";

type CategoryDropdownProps = {
  department: Department;
};

const MENSWEAR_COLUMNS = [
  ["tops"],
  ["bottoms"],
  ["outerwear"],
  ["footwear"],
  ["accessories"],
  ["tailoring"],
];

const WOMENSWEAR_COLUMNS = [
  ["tops"],
  ["bottoms", "dresses"],
  ["outerwear"],
  ["footwear", "jewelry"],
  ["accessories"],
  ["bags-luggage"],
];

const excludedSubcategories = [
  "accessories.supreme",
  "accessories.periodicals",
  "womens_bottoms.joggers",
  "womens_bottoms.sweatpants",
  "womens_bags_luggage.toiletry_pouches",
  "womens_jewelry.body_jewelry",
  "womens_jewelry.brooches",
  "womens_jewelry.charms",
  "womens_jewelry.cufflinks",
];

const excludedCategories: string[] = [];

const CategoryDropdown = ({ department }: CategoryDropdownProps) => {
  const categoriesResource = useCategories();

  const departmentCategories = React.useMemo(() => {
    if (categoriesResource.type !== "Completed") return [];

    const categories = Object.values(categoriesResource.value);

    const categoriesWithExclusions = categories
      .filter((category) => !excludedCategories.includes(category.path))
      .map((category) => {
        return {
          ...category,
          subcategories: category.subcategories.filter((subcategory) => {
            return !excludedSubcategories.includes(subcategory.path);
          }),
        };
      });

    return department === "menswear"
      ? categoriesWithExclusions.filter(({ path }) => !path.includes("womens"))
      : categoriesWithExclusions.filter(({ path }) => path.includes("womens"));
  }, [categoriesResource.type, department]);

  const categoryBaseLink =
    department === "menswear" ? "/categories" : "/categories/womenswear";

  const columnPaths =
    department === "menswear" ? MENSWEAR_COLUMNS : WOMENSWEAR_COLUMNS;

  const orderedCategories = React.useMemo(
    () =>
      columnPaths.map((column) =>
        column.map((path) => {
          const category = departmentCategories.find(
            ({ slug }) => slug === path,
          );
          return category;
        }),
      ),
    [columnPaths, departmentCategories],
  );

  if (categoriesResource.type !== "Completed") return null;

  return (
    <Content>
      <ContentHeader>Shop By Category</ContentHeader>
      <ContentContainer>
        {orderedCategories.map((column) => (
          <ContentColumn key={column[0]?.slug}>
            {column.map((category) =>
              category ? (
                <Fragment key={category.slug}>
                  <ContentColumnHeader
                    key={category.slug}
                    href={`${categoryBaseLink}/${category.slug}`}
                    from="sub_header_category_dropdown"
                  >
                    {category.name}
                  </ContentColumnHeader>
                  {category.subcategories.map((subcategory) => {
                    return (
                      <ContentLink
                        key={subcategory.name}
                        href={`${categoryBaseLink}/${subcategory.slug}`}
                        from="sub_header_category_dropdown"
                        department={department}
                      >
                        {subcategory.name}
                      </ContentLink>
                    );
                  })}
                </Fragment>
              ) : null,
            )}
          </ContentColumn>
        ))}
      </ContentContainer>
      <ContentCTA
        href={`${categoryBaseLink}/all`}
        from="sub_header_category_dropdown"
        department={department}
      >
        See All Categories
      </ContentCTA>
    </Content>
  );
};

export default CategoryDropdown;

import React from "react";
import classNames from "classnames";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

import {
  Dialog,
  DialogTrigger,
  DialogOverlay,
  DialogContent,
  DialogPortal,
  DialogClose,
  DialogTitle,
} from "storefront/ui/dialog";
import CircleCross from "storefront/components/Icons/CircleCross";

import styles from "./Drawer.module.scss";

type DrawerContentProps = React.ComponentPropsWithoutRef<
  typeof DialogContent
> & {
  title: string;
  direction?: "left" | "right";
};

const Drawer = Dialog;

const DrawerTrigger = DialogTrigger;

const DrawerOverlay = DialogOverlay;

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DialogContent>,
  DrawerContentProps & { onClose?: () => void }
>(({ className, children, key, direction = "left", title, ...props }, ref) => {
  const contentClasses = classNames(
    styles.container,
    {
      [styles.left]: direction === "left",
      [styles.right]: direction === "right",
    },
    className,
  );
  return (
    <DialogPortal>
      <DialogOverlay data-testid="drawer-overlay" />
      <DialogContent
        key={String(key)}
        ref={ref}
        className={contentClasses}
        aria-describedby={undefined}
        {...props}
      >
        <VisuallyHidden.Root>
          <DialogTitle>{title}</DialogTitle>
        </VisuallyHidden.Root>
        {children}
        <DialogClose
          onClick={() => {
            props.onClose?.();
          }}
          className={styles.close}
        >
          <CircleCross color="light" />
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  );
});
DrawerContent.displayName = DialogContent.displayName;

export { Drawer, DrawerTrigger, DrawerOverlay, DrawerContent };

import { combineReducers } from "redux";
import type { Reducer, AnyAction } from "redux";
import { reducer as formReducer } from "redux-form";
import onboarding from "storefront/Onboarding/reducer";
import id from "./id";
import sessionReducer from "./session";
import listingsReducer from "./listings";
import conversationsReducer from "./conversations";
import fittingRoomReducer from "./fitting_room";
import sellFormReducer from "./sellForm";
import filteringReducer from "./filtering";
import articlesReducer from "./articles";
import internalToolsReducer from "./internal_tools";
import security from "./security";
import capsules from "./capsules";

const reducer: Reducer<Record<string, unknown>, AnyAction> = combineReducers({
  articles: articlesReducer,
  assets: id,
  capsules,
  config: id,
  conversations: conversationsReducer,
  filters: filteringReducer,
  fittingRoom: fittingRoomReducer,
  form: formReducer,
  homepage: id,
  internalTools: internalToolsReducer,
  listings: listingsReducer,
  onboarding,
  security,
  sellForm: sellFormReducer,
  session: sessionReducer,
});

export default reducer;
